
@media print {
  body {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
    color-adjust: exact !important;                 /*Firefox*/
  }


  .content-wrapper {
    /* overflow-y: hidden; */
  }

  @page {
    margin-top: 0cm;
    margin-bottom: 0cm;
    margin-left: 0cm;
    margin-right: 0cm;
    size: A4 portrait;
    max-height:100%; 
    max-width:100%
}

   /* .content-wrapper, react-grid-layout {
    overflow-y: auto !important;
    height: 100%;
    
  }  */

  .react-grid-layout  { 
    /* float: none !important;  */
    background-color: #fff !important;
  } 

  .react-resizable-handle {
    display: none !important;
  }

  .react-grid-item 	{  
    page-break-after: always !important;
    transition: none !important;
    position: static !important;
    transform: none !important;
    width: calc(100% - 10px) !important;
    margin: 5px !important;
   }

   .scroll-control-container{
     display: none !important;
   }
}

.pages {
  flex: 1;
  min-width: 0;
}
