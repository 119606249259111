.lasso-img-wrapper {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.lasso-img-wrapper:hover {
  box-shadow: 0 0 0 13px rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.06);
}
