.color-picker-container {
}

.color-picker-icon-wrapper {
}

.color-picker-icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 2px solid #bbb;
  border-radius: 5px;
}
