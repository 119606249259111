.nav-bar-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.graph-drawer-contents {
  display: flex;
  flex-direction: column;
  justify-content: auto;
  height: 100%;
}