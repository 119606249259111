.button-preset-selector {
  box-shadow: 3px 3px 10px rgba(0,0,0,.3);
  display: inline-block;
  transition: linear all .2s;
  cursor: pointer;
  height: 24px;
  width: 24px;
 border-radius: 3px;
}

.button-preset-selector:active {
box-shadow: none;
}