
.logo-container {
  display: flex;
  /* background-color: #fff; */
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 64px);
  /* width: 100vw; */
}

.logo {
  overflow: hidden;

  animation: bounce 0.5s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(.5,0.05,1,.5);
  animation-iteration-count: infinite;
}

@keyframes bounce {
  from { transform: translate3d(0, 0, 0);     }
  to   { transform: translate3d(0, 15px, 0); }
}