.lasso-selection-inactive {
    background: transparent;
}
.lasso-selection-color-1 {
    background: #375E97;
    color: #fff;
}
.lasso-selection-color-2 {
    background: #FF0066;
    color: #fff;
}