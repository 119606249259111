.checkbox-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.upload-file-btn {
  width: 100%;
  margin-top: 32px !important;
}

.upload-file-wrapper {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.upload-file-placeholder {
  width: 40px !important;
  height: 40px !important;
}
