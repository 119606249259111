.auth-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100vw; */
  min-height: 100vh;
  flex-direction: column;
}

.auth-container {
  min-height: 440px;
}

.auth-login-register-container {
  padding: 20px;
  /* min-height: 418px; */
}