.profile-wrapper {
  flex: 3;
  overflow: auto;
  padding: 8px;
  position: relative;
}
.column-divider {
  display: inline-block;
  height: 100%;
  width: 10px;
  border-right: 1px solid #e0e0e0;
  margin-right: 10px;
}

.close-icon {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
