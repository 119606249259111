
.grid-lines line {
  stroke: gray;
  stroke-opacity: 0.2;
  shape-rendering: crispEdges;
}

.grid-lines path {
  stroke-width: 0;
}

.yAxis-left path {
  stroke: blue;
  stroke-width: 2;
  /* stroke-opacity: 0.2; */
  /* shape-rendering: crispEdges; */
}
.yAxis-left line {
  stroke: blue;
  stroke-width: 2;
  /* stroke-opacity: 0.2; */
  /* shape-rendering: crispEdges; */
}

.yAxis-right path {
  stroke: red;
  stroke-width: 2;
  /* stroke-opacity: 0.2; */
  /* shape-rendering: crispEdges; */
}
.yAxis-right line {
  stroke: red;
  stroke-width: 2;
  /* stroke-opacity: 0.2; */
  /* shape-rendering: crispEdges; */
}

/* .pred-axisWhite line{
  stroke: #fff;
} */

/* .pred-axisWhite path{
  stroke: #fff;
} */

.pred-axisWhite text{
  fill: #fff;
} 
.pred-lineWhite line, .pred-lineWhite path{
  stroke: #fff;
} 