svg#svg {
  /* border: 1px solid rgba(0, 0, 0, 0.08); */
  /* background: rgba(0,0,0,.04); */
  border-radius: 3px;
  
}
svg#svg.zoom {
  cursor: grab;
}

svg#svg.zoom:active {
  cursor: grabbing;
}

svg#svg.selection, svg#svg.selection:active {
  cursor: crosshair;
}



.tooltip {
  position: absolute;
  padding: 5px;
  display: inline-block;
  font-size: 13px;
  font-weight: lighter;
  color: #fff;
  background: #6d6d6d;
  border: 0px;
  border-radius: 4px;
  pointer-events: none;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)
}

.tooltip p {
  margin: 5px 0;
}


rect.selection {
  stroke          : gray;
  stroke-dasharray: 4px;
  stroke-opacity  : 0.5;
  opacity: .2;
}

.selected-blue {
  fill: blue;
  /* stroke: black; */
}
.selected-red {
  fill: darkred;
  /* stroke: black; */
}

g.state circle {
  stroke  : gray;
  cursor  : pointer;
}

g.state circle.inner {
  fill    : white;
}

g.state circle.outer {
  display         : none;
  stroke-dasharray: 4px;
  stroke-opacity  : 0.5;
  fill            : transparent;
}

g.state.selected circle.outer {
  display         : inline;
}

g.state text {
  font                : 12px sans-serif;
  font-weight         : bold;
  pointer-events      : none;
}
