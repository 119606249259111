.axisWhite line{
  stroke: #fff;
}

.axisWhite path{
  stroke: #fff;
}

.axisWhite text{
  fill: #fff;
} 