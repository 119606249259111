* {
    box-sizing: border-box;
}

html{
    height: 100%;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
    overflow: hidden;
}

textarea:focus,
input:focus,
button:focus {
    outline: none;
}

.hidden {
    display: none;
}
