.main-page-wrapper {
  margin: 20px;
}

.main-title-wrapper {
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-expand-wrapper {
  display: flex;
}

.expand-control-wrapper {
  /* margin-left: -16px; */
}

.tree-profile-wrapper {
  display: flex;
  height: calc(100vh - 148px);
  border-top: 1px solid #e0e0e0;
}

.tree-wrapper {
  overflow: auto;
  flex: 2;
}

.add-portfolio-btn {
  /* height: 35px; */
}
