.profile-heading-wrapper {
  display: flex;
  margin-bottom: 20px;
}

.profile-title-wrapper {
  flex: 1;
}

.portfolio-controls-section {
  display: flex;
  justify-content: space-between;
}

.portfolio-btn {
  margin-right: 15px;
}

.cell-name {
  cursor: pointer;
  color: #0a84ae;
}
.cell-name:hover {
  color: #053b4e;
}
