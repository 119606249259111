.color {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 2px;
}

.red {
  background: red;
}

.blue {
  background: blue;
}

.color-text {
  display: inline-block;
  margin: 0 10px
}