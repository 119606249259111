.register-controls-container {
/* min-width: 430px;
min-height: 361px; */
}

.margin-vertical {
  margin: 16px 0 8px !important;
}

.margin-top {
  margin-top: 16px !important;
}
