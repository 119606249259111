.tooltip{
  position:relative;
  display: inline-block;
}
.tooltip[data-title]:hover:after {
  content: attr(data-title);
  padding: 4px 8px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 110%;
  white-space: nowrap;  
  border-radius: 5px;  
  background:#000;
}